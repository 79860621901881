function executeFunction(func) {
    switch (func) {
        case 'function:utc_offset':
            return moment().utcOffset() / 60;
        // Add more functions here
    }
}

function getCustomFieldsData() {
    var inputs = Array.from(document.querySelectorAll('.field-input-custom'));
    return inputs.reduce((cf, element) => {
        let value;
        switch (element.getAttribute('data-type')) {
            case 'number':
                value = parseFloat(element.value);
                break;
            case 'checkbox':
                value = element.value && true;
                break;
            case 'function':
                value = executeFunction(element.value);
                break;
            default:
                value = element.value;
        }

        cf[element.getAttribute('data-name')] = value;

        return cf;
    }, {});
}
